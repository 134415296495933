
import dcbAPI from "@/services/dcb/httpdcb";
import axios from "axios";

export default {
  name: "dcb",
  data() {
    return {
      // receive methods listItens
      itemsList: [],

      //all itens to search
      allItems: [],

      // body dcb
      AddItems: {
        //"contaId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "contaId": "00000000-0000-0000-0000-000000000000",
        "integracaoId": "",
        "descricao": "",
        "codigoDcb": "",
      },
      //search
      searchItems: '',

      //page numbers

      pgNumb: '',

      // about pages
      totalPages: '',
      currentPage: 1,
      itensQtd: '',
    };
  },
  components: {
    //  Methods,
  },

  methods: {
    previousPage(page) {
      if (page === this.currentPage && (page <= this.totalPages && page > 1)) {
        this.currentPage -= 1
        this.listItens()
        return
      }

      this.currentPage = page;
      this.listItens()
    },
    nextPage(page) {
      if (page === this.currentPage && (page < this.totalPages && page >= 1)) {
        this.currentPage += 1
        this.listItens()
        return
      }

      this.currentPage = page;
      this.listItens()
    },

    //Get list Mehtods and pages from Api
    listItens() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/dcb/obter-grid/${this.currentPage}`)
        .then(res => {
          this.itemsList = res.data.itens;
          this.totalPages = res.data.totalDePaginas;
          this.currentPage = res.data.pagina;
          this.itensQtd = res.data.total;

        });
    },

    //Send itens values to api - button Salvar
    saveItens() {
      dcbAPI.dcbaddNew(this.AddItems)
        .then(res => {

          alert("salvo com sucesso", res),
            this.listItens(); // update item on screen 
          this.AddItems = {}; //clear fields
        });
    },
    //To edit values itens - button Editar
    editAction(AddItems) {
      this.AddItems = AddItems
    },

    // Send edited values to APi
    updateItens() {
      dcbAPI.dcbUpdate(this.AddItems)
        .then(res => {
          alert("alterado com sucesso", res),
            this.listItens(); // update item on screen 
          this.AddItems = {}; //clear
        });
    },

    // Delet Itens from Api - button Apagar
    deletItens(AddItems) {
      if (confirm('Deseja excluir?')) {
        axios.post(
          `${process.env.VUE_APP_BASE_URL}/dcb/remover/` + AddItems.id,
        )
          .then((res) => {
            alert("Removido com sucesso", res),
              this.listItens();
            this.errors = []
          })
      }
    },
    // Filter
    allDescrition() {
      let i = 1
      let allPages = '3'
      let arr = []

      //console.log('Total pages', allPages)   

      for (i = 1; i <= allPages; i++) {
        arr.push(axios.get(`${process.env.VUE_APP_BASE_URL}/dcb/obter-grid/` + i))
        Promise.all(arr).then((res) => {
          arr = res


          // console.table(okk);
        })
      }
    },


  },//End Methods
  mounted() {
    //Start with itens list
    this.listItens();
    this.$store.dispatch('dcb/dcbSearch')

    //this.allDescrition();

    // console.log('Todos itens',  this.allItems)    
  },

  computed: {
    //Function filter Search
    itensFiltered() {
      let values = []
      values = this.itemsList.filter((description) => {
        return description.descricao.toLowerCase().indexOf(this.searchItems.toLowerCase()) > -1
      });
      return values;
    },


    itensFilDCB() {
      const { dcb } = this.$store.state.dcb
      let valuesDCB = []
      valuesDCB = dcb.filter((description) => {
        return description.descricao.toLowerCase().indexOf(this.searchItems.toLowerCase()) > -1
      });
      return valuesDCB;
    },





  },
};



