import axios from "axios";


const keyUser = localStorage.getItem("userKey");
const tokenUser = JSON.parse(keyUser);

axios.defaults.headers.common["Authorization"] = `Bearer ${tokenUser}`;


  export default{

      dcbaddNew:(AddItems) => {
        return  axios.post(
          `${process.env.VUE_APP_BASE_URL}/dcb/novo`, AddItems)
      },
      
      
      dcbUpdate:(AddItems) => {
        return  axios.post(
          `${process.env.VUE_APP_BASE_URL}/dcb/editar`, AddItems)
            
      },

     

  }


//https://hapi-farmafacil.prismafive.com.br/swagger/index.html
 